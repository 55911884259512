var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"links-container"},[_c('v-btn',{staticClass:"text-subtitle-1 text-capitalize mx-1 font-weight-medium",attrs:{"to":{
      name: 'dashboard',
      params: {
        tenant: _vm.slug
      }
    },"dark":"","small":"","depressed":"","color":"transparent","input-value":_vm.$route.name == 'dashboard' || _vm.$route.name == 'project',"title":"Visit your Dashboard","exact":""}},[_vm._v(" Dashboard ")]),_c('v-btn',{staticClass:"text-subtitle-1 text-capitalize mx-1 font-weight-medium",attrs:{"to":{
      name: 'agents',
      params: { tenant: _vm.slug }
    },"dark":"","small":"","depressed":"","color":"transparent","title":"Manage your team's agents"}},[_vm._v(" Agents ")]),_c('v-btn',{staticClass:"text-subtitle-1 text-capitalize mx-1 font-weight-medium",attrs:{"to":{
      name: 'api',
      params: { tenant: _vm.slug }
    },"dark":"","small":"","depressed":"","color":"transparent","title":"Experiment with GraphQL queries using the Interactive API"}},[_vm._v(" Interactive API ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }